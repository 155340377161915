<template>
  <VTextField
    v-model="title"
    :append-icon="appendIcon"
    :autofocus="autofocus"
    :background-color="backgroundColor"
    class="title-edit"
    :class="[!outlined ? 'round-corners' : '']"
    :color="color"
    dense
    :disabled="disabled"
    :height="height"
    :hide-details="false"
    :outlined="outlined"
    :placeholder="placeholder"
    :prepend-icon="prependIcon"
    :prepend-inner-icon="prependInnerIcon"
    :rounded="rounded"
    :rules="rules"
    @input="titleChanged"
  />
</template>

<script>
export default {
  name: 'EditableTitle',
  props: {
    value: [String],
    placeholder: [String],
    appendIcon: [String],
    prependIcon: [String],
    prependInnerIcon: [String],
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: '#F2F2F2',
    },
    color: {
      type: String,
      default: '#47B54C',
    },
    height: {
      type: [String, Number],
      default: 40,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input'],
  data() {
    return {
      title: this.value ? this.value : '',
    };
  },
  methods: {
    titleChanged() {
      this.$emit('input', this.title);
    },
  },
  watch: {
    value(newValue) {
      this.title = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/page.scss';

.title-edit {
  color: $default_dark_gray_color;
  font-family: $default_bold_font;
  font-size: 28px;
}
.round-corners {
  border-radius: 5px;
}
</style>
