<template>
  <div>
    <VAutocomplete
      :key="createTariffDialog"
      v-model="selectedTariff"
      append-icon="mdi-chevron-down"
      class="mr-2 br-8"
      dense
      hide-details
      hide-no-data
      hide-selected
      item-text="title"
      :items="deliveryTariffs"
      label="Тариф"
      outlined
      placeholder="Выберите из списка"
      return-object
      :rules="[(v) => !!v || 'Обязательное значение']"
      @change="$emit('input', $event)"
    >
      <template v-slot:prepend-item>
        <div>
          <VBtn
            class="elevation-0 mx-2 subtitle-2 pl-1 pr-2"
            color="#2857FC"
            text
            @click="createTariffDialog = true"
          >
            + Новый тариф
          </VBtn>
        </div>
      </template>
    </VAutocomplete>
    <VDialog
      v-model="createTariffDialog"
      content-class="br-12"
      max-width="1400px"
    >
      <VCard class="br-12 pt-2 d-flex flex-column overflow-auto" max-height="90vh">
        <div class="d-flex pl-4 pt-2 pb-3">
          <Breadcrumbs :items="breadcrumbs" />
        </div>
        <VDivider />
        <Tariffs class="tariffs"/>
        <div class="footer mt-auto">
          <div class="d-flex justify-end">
            <VBtn
              class="subtitle-2 font-weight-bold mr-2"
              text
              @click="createTariffDialog = false"
            >
              Отмена
            </VBtn>
            <VBtn
              class="subtitle-2 elevation-0 br-8 white--text font-weight-bold mr-7 px-5"
              color="#E43539"
              @click="createTariffDialog = false"
            >
              Сохранить
            </VBtn>
          </div>
        </div>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Tariffs from '@/pages/Tariffs.vue';
import Breadcrumbs from '@/components/reusable/Breadcrumbs.vue';

export default {
  name: 'DeliveryTariffSelection',
  components: { Breadcrumbs, Tariffs },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['input'],
  data() {
    return {
      selectedTariff: null,
      createTariffDialog: false,
      breadcrumbs: [
        {
          text: 'Зоны доставки',
        },
        {
          text: 'Создать новую зону',
          disabled: true,
        },
      ],
    };
  },
  created() {
    if (!this.deliveryTariffs.length) {
      this.getDeliveryTariffs();
    }
    this.selectedTariff = this.value;
  },
  computed: {
    ...mapGetters(['deliveryTariffs']),
  },
  methods: {
    ...mapActions(['getDeliveryTariffs']),
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(value) {
        this.selectedTariff = value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/main.scss';

.tariffs {
  margin-bottom: 100px;
}
.footer {
  position: sticky;
  padding: 16px 0 52px 0;
  bottom: -2px;
  background-color: #FAFAFB;
  z-index: 1;
  border-top: 1px solid #e0e0e0;
}
</style>
