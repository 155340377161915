<template>
  <div class="d-flex flex-column">
    <div
      v-for="(field, index) in fields"
      :key="index"
      class="d-flex align-center"
    >
      <div class="cost-title">
        {{ field.title }}:
      </div>
      <VChipGroup
        v-model="conditions[field.key]"
        active-class="cost-selected"
        mandatory
      >
        <VChip
          v-for="cost in field.costs"
          :key="cost"
          class="cost-chip justify-center"
          :value="cost"
        >
          {{ cost }}
        </VChip>
        <VBtn
          class="align-self-center default-btn white--text"
          color="#4caf50"
          elevation="0"
          fab
          rounded
          x-small
          @click="addCost(field)"
        >
          <VIcon>mdi-plus-circle</VIcon>
        </VBtn>
      </VChipGroup>
    </div>
    <EditDeliveryModal
      :show="showAdditionDialog"
      :title="modalTitle"
      :type="modalKey"
      @appearance="showAdditionDialog = $event"
      @save="pushConditionCost"
    />
  </div>
</template>

<script>
import EditDeliveryModal from '@/components/EditDeliveryModal.vue';
import { DeliveryCondition } from '@/serializers/deliveryConditionsSerializer';

function buildCondition(title, key, costs) {
  return {
    title,
    key,
    costs,
  };
}

const fields = [
  buildCondition(
    'На сколько % увеличить стоимость доставки?',
    'deliveryIncreaseRate',
    [10, 30, 50, 100, 200, 300],
  ),
  buildCondition(
    'Минимальная стоимость доставки',
    'minDelivery',
    [499, 500, 700, 1000],
  ),
  buildCondition(
    'Максимальная стоимость доставки',
    'maxDelivery',
    [2000, 3000],
  ),
  buildCondition(
    'На сколько % увеличить мин.сумму заказа?',
    'moaIncreaseRate',
    [10, 30, 50, 100, 200, 300],
  ),
  buildCondition(
    'Минимальная стоимость минимального заказа',
    'minMoa',
    [1500, 2000, 2500, 3000],
  ),
  buildCondition(
    'Максимальная стоимость минимального заказа',
    'maxMoa',
    [1500, 2000, 2500, 3000],
  ),
  buildCondition(
    'Сколько % курьер зарабатывает с повышенного тарифа?',
    'courierFee',
    [0, 10, 30, 50, 70, 100],
  ),
];

export default {
  name: 'CreateHotDeliveryCondition',
  components: { EditDeliveryModal },
  props: {
    value: {
      type: DeliveryCondition,
      default() {
        return new DeliveryCondition();
      },
    },
  },
  emits: ['conditions-change'],
  data() {
    return {
      fields,
      conditions: {},
      showAdditionDialog: false,
      modalTitle: '',
      modalKey: '',
    };
  },
  methods: {
    addCost(field) {
      this.modalTitle = field.title;
      this.modalKey = field.key;
      this.showAdditionDialog = true;
    },
    pushConditionCost(newCost) {
      this.fields.forEach((field) => {
        if (field.key === newCost.type) {
          field.costs.push(newCost.cost);
          this.conditions[newCost.type] = newCost.cost;
        }
      });
    },
  },
  watch: {
    conditions: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit('conditions-change', this.conditions);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/mixin.scss';

.cost-title {
  font-size: 12px;
  width: 340px;
  margin-right: 10px;
}
.cost-chip {
  width: 70px;
}
.cost-selected {
  color: white !important;
  background: $default_green_color !important;
}
::v-deep{
  .v-slide-group__content{
    flex-wrap: wrap;
    flex: auto;
  }
}
</style>
