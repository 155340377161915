import { render, staticRenderFns } from "./CreateHotDeliveryTariff.vue?vue&type=template&id=f8a11b88&scoped=true"
import script from "./CreateHotDeliveryTariff.vue?vue&type=script&lang=js"
export * from "./CreateHotDeliveryTariff.vue?vue&type=script&lang=js"
import style0 from "./CreateHotDeliveryTariff.vue?vue&type=style&index=0&id=f8a11b88&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8a11b88",
  null
  
)

export default component.exports