<template>
  <VDialog v-model="show" max-width="364">
    <VCard class="pa-5 br-12">
      <div class="d-flex justify-space-between mb-2">
        <span class="section-title mt-1 ml-1">Удалить все зоны?</span>
        <VBtn
          icon
          small
          @click="emitClose"
        >
          <VIcon size="22" v-text="'mdi-close'" />
        </VBtn>
      </div>
      <div class="subtitle-1 mx-1 mb-6">
        {{ `Вы точно хотите удалить все ${zonesToDeleteCount} ${zoneWithEnding}?` }}<br>
        Если удалить, то нельзя будет восстановить и придется заново все создавать вручную
      </div>
      <div class="d-flex justify-end pr-1">
        <VBtn
          class="br-8 px-3 mr-2 subtitle-1"
          color="#6D7885"
          height="38"
          text
          @click="emitClose"
        >
          Отменить
        </VBtn>
        <VBtn
          class="br-8 px-3 subtitle-1"
          color="#C84620"
          height="38"
          outlined
          @click="emitDelete"
        >
          Да, удалить
        </VBtn>
      </div>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    zonesToDeleteCount: {
      type: Number,
      default: 1,
    },
  },
  emits: ['close', 'delete'],
  computed: {
    zoneWithEnding() {
      const lastTwoDigits = this.zonesToDeleteCount.toString().slice(-2);
      const lastDigit = this.zonesToDeleteCount.toString().slice(-1);
      const endings = {
        зон: [0, 5, 6, 7, 8, 9, 11, 12, 13, 14],
        зону: [1],
        зоны: [2, 3, 4],
      };
      const endingsAsDict = {};
      Object.keys(endings).forEach((key) => {
        // eslint-disable-next-line no-return-assign
        endings[key].forEach((int) => endingsAsDict[int] = key);
      });
      return endingsAsDict[lastTwoDigits] || endingsAsDict[lastDigit];
    },
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    emitDelete() {
      this.$emit('delete');
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/scss/main.scss';

::v-deep {
  .subtitle-1 {
    line-height: 22px;
    letter-spacing: 0.5px;
  }
}
</style>
