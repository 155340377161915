<template>
  <VCard class="ma-7" flat>
    <div class="pb-5 d-flex">
      <span class="page_title">Тарифы доставки</span>
      <div v-if="state === 'LIST'" class="pl-8">
        <VBtn
          class="default-btn white--text"
          color="#4caf50"
          rounded
          @click="goToCreating"
        >
          <VIcon left>
            mdi-plus-circle
          </VIcon>
          Добавить тариф
        </VBtn>
      </div>
    </div>
    <EditDeliveryTariff
      v-if="state === 'CREATING' || state === 'EDITING'"
      :tariff="editedTariff"
      @save="saveEditedTariff"
    />
    <VDivider class="mb-7" />
    <DeliveryTariffTable @deleteTariff="deleteTariff" @editTariff="goToEditing" />
  </VCard>
</template>

<script>
import DeliveryTariffTable from '@/components/DeliveryTariffTable.vue';
import EditDeliveryTariff from '@/components/EditDeliveryTariff.vue';
import { createDeliveryTariff, deleteDeliveryTariff, updateDeliveryTariff } from '@/api/api';
import { eventHub, events } from '@/global/eventhub';

export default {
  components: {
    DeliveryTariffTable, EditDeliveryTariff,
  },
  data() {
    return {
      state: 'LIST',
      editedTariff: null,
    };
  },
  methods: {
    goToViewList() {
      this.state = 'LIST';
      this.editedTariff = null;
    },
    goToCreating() {
      this.state = 'CREATING';
      this.editedTariff = null;
    },
    goToEditing(tariff) {
      this.$nextTick().then(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      });
      this.state = 'EDITING';
      this.editedTariff = tariff;
    },
    async deleteTariff(tariff) {
      await deleteDeliveryTariff(tariff.pk);
      eventHub.$emit(events.deliveryTariffDeleted);
    },
    async saveEditedTariff(editedTariff) {
      if (this.state === 'CREATING') {
        await createDeliveryTariff(editedTariff.deserialized)
          .then((newDeliveryTariff) => {
            eventHub.$emit(events.deliveryTariffCreated, newDeliveryTariff);
          });
      } else if (this.state === 'EDITING') {
        await updateDeliveryTariff(this.editedTariff.pk, editedTariff.deserialized)
          .then((updatedDeliveryTariff) => {
            eventHub.$emit(events.deliveryTariffUpdated, updatedDeliveryTariff);
          });
      }
      this.goToViewList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

</style>
