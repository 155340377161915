<template>
  <div
    class="d-flex flex-column align-end snackbar-div"
  >
    <VSnackbar
      v-for="(item, index) in items"
      :key="index"
      v-model="item.show"
      class="white--text mt-2 snackbar"
      :timeout="5000"
    >
      <VBtn
        v-if="isPrepend"
        class="mr-1 ml-0"
        color="white"
        icon
        @click="item.show = false"
      >
        <VIcon size="22">mdi-trash-can-outline</VIcon>
      </VBtn>
      <span class="mr-auto">{{ item.text }}</span>
      <VBtn
        v-if="!isPrepend"
        class="ml-1"
        color="white"
        icon
        @click="item.show = false"
      >
        <VIcon size="24">mdi-close</VIcon>
      </VBtn>
    </VSnackbar>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: '#4D4F67',
    },
    isPrepend: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .snackbar {
    position: relative;
    .v-snack__wrapper {
      border-radius: 8px;
      min-width: auto;
      position: relative;
    }
  }
}

.snackbar-div {
  position: fixed;
  z-index: 1000;
  right: 40px;
  bottom: 40px;
}
</style>
