<template>
  <VCard class="ma-7" flat>
    <div class="pb-5 d-flex">
      <span class="page_title">Увеличенные тарифы</span>
      <div v-if="!showTariffCreation" class="pl-8">
        <VBtn
          class="default-btn white--text"
          color="#4caf50"
          rounded
          @click="showTariffCreation = true"
        >
          <VIcon left>
            mdi-plus-circle
          </VIcon>
          Добавить увеличенный тариф
        </VBtn>
      </div>
    </div>

    <CreateHotDeliveryTariff
      v-if="showTariffCreation"
      @save="createIncreasedTariff"
    />
    <UniversalTable
      class="table"
      :head-rows="table.headers"
    >
      <template v-slot:search>
        <th class="py-2">
          <VTextField
            v-model="title"
            background-color="#5B5B5B"
            clearable
            dark
            dense
            hide-details
            outlined
            prepend-inner-icon="mdi-magnify"
            solo
            @keyup="fetchHotDeliveryTariffs"
          />
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th>
          <VTextField
            v-model="searchCourierFee"
            background-color="#5B5B5B"
            clearable
            dark
            dense
            hide-details
            outlined
            prepend-inner-icon="mdi-magnify"
            solo
            @keyup="fetchHotDeliveryTariffs"
          />
        </th>
        <th>
          <VSelect
            v-model="tariffState"
            background-color="#5B5B5B"
            class="mx-auto"
            clearable
            dark
            dense
            hide-details
            item-text="title"
            item-value="value"
            :items="tariffStates"
            label="Все"
            outlined
            solo
            style="width: 100px"
            @change="fetchHotDeliveryTariffs"
          />
        </th>
      </template>
      <template v-slot:tr>
        <tr
          v-for="(item, index) in table.items"
          :key="item.id"
          class="tr"
        >
          <td class="py-3">
            <span>
              {{ item.title }}
            </span>
          </td>
          <td class="text-center">
            <span>
              {{ item.deliveryIncreaseRate }}%
            </span>
          </td>
          <td class="text-center">
            <span>
              {{ item.minDelivery }}
            </span>
          </td>
          <td class="text-center">
            <span>
              {{ item.maxDelivery }}
            </span>
          </td>
          <td class="text-center">
            <span>
              {{ item.moaIncreaseRate }}%
            </span>
          </td>
          <td class="text-center">
            <span>
              {{ item.minMoa }}
            </span>
          </td>
          <td class="text-center">
            <span>
              {{ item.maxMoa }}
            </span>
          </td>
          <td class="text-center">
            <span>
              {{ item.courierFee }}%
            </span>
          </td>
          <td
            class="text-center"
          >
            <div class="d-flex">
              <VSwitch
                v-model="item.isActive"
                class="mx-auto"
                color="success"
                inset
                @change="changeState(item, index)"
              ></VSwitch>
            </div>
          </td>
        </tr>
      </template>
    </UniversalTable>
    <PrevNextButtons
      :current-page="currentPage"
      :pages-count="totalPagesCount"
      @next-page="changePage(1)"
      @previous-page="changePage(-1)"
    />
  </VCard>
</template>

<script>
import UniversalTable from '@/components/UniversalTable.vue';
import CreateHotDeliveryTariff from '@/components/CreateHotDeliveryTariff.vue';
import PrevNextButtons from '@/components/reusable/PrevNextButtons.vue';
import { increasedTariffs } from '@/conditions/tablesHeaders';
import {
  fetchHotDeliveryTariffs,
  patchHotTariffState,
  createHotTariff,
} from '@/api/api';

const tariffStates = {
  Все: undefined,
  Вкл: true,
  Выкл: false,
};

const statesArray = Object.keys(tariffStates).map((key) => (
  Object.freeze({
    title: key,
    value: tariffStates[key],
  })
));

export default {
  components: {
    UniversalTable,
    CreateHotDeliveryTariff,
    PrevNextButtons,
  },
  data() {
    return {
      table: {
        headers: increasedTariffs,
        items: [],
      },
      tariffStates: statesArray,
      title: '',
      tariffState: 'Все',
      currentPage: 1,
      totalPagesCount: 1,
      searchCourierFee: '',
      showTariffCreation: false,
    };
  },
  mounted() {
    this.fetchHotDeliveryTariffs();
  },
  methods: {
    fetchHotDeliveryTariffs() {
      const queryParams = {
        search_courier_fee: this.searchCourierFee || undefined,
        is_active: typeof this.tariffState === 'boolean' ? this.tariffState : undefined,
        search_title: this.title || undefined,
        page: this.currentPage,
      };
      fetchHotDeliveryTariffs(queryParams).then((tariffs) => {
        this.table.items = tariffs.data;
        this.totalPagesCount = tariffs.totalPages;
      });
    },
    changeState(tariff, indexOfTariff) {
      if (typeof tariff.isActive === 'boolean') {
        patchHotTariffState(tariff.id, tariff.isActive).catch(() => {
          this.$nextTick().then(() => {
            this.table.items[indexOfTariff].isActive = !this.table.items[indexOfTariff].isActive;
          });
        });
      }
    },
    createIncreasedTariff(deserializedTariff) {
      this.showTariffCreation = false;
      createHotTariff(deserializedTariff).then(() => {
        this.fetchHotDeliveryTariffs();
      });
    },
    changePage(incOrDecr) {
      this.currentPage += incOrDecr;
      this.fetchHotDeliveryTariffs();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

.tr>* {
  font-size: 14px !important;
}

::v-deep {
  .table {
    .v-data-table__wrapper {
      box-shadow: 0 0 5px 1px rgb(0 0 0 / 20%);
      border-radius: 10px;
      padding: 4px 0;
    }
    thead {
      span {
        font-size: 14px;
      }
    }
  }
}
</style>
