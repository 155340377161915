<template>
  <div>
    <VDivider />
    <EditableTitle
      v-model="title"
      class="title-view my-4"
      placholder="Новый тариф"
    />
    <div class="d-flex flex-wrap justify-start">
      <div
        v-for="(_, index) in conditions"
        :key="index"
        class="condition"
      >
        <span class="condition-title">Условие #{{ index + 1 }}</span>
        <VBtn
          class="ml-2"
          color="#D0021B"
          icon
          small
          @click="deleteCondition(index)"
        >
          <VIcon>mdi-close</VIcon>
        </VBtn>
        <EditDeliveryCondition v-model="conditions[index]" />
      </div>
    </div>
    <div class="d-flex flex-column">
      <VBtn
        class="align-self-start default-btn white--text mt-4 mb-4"
        color="#4caf50"
        rounded
        @click="addNewCondition"
      >
        <VIcon left>
          mdi-plus-circle
        </VIcon>
        Добавить условие
      </VBtn>
      <VBtn
        class="align-self-center default-btn white--text mb-4"
        color="#4caf50"
        rounded
        @click="saveTariff"
      >
        Сохранить {{ title }} в список тарифов
      </VBtn>
    </div>
  </div>
</template>

<script>
import EditDeliveryCondition from '@/components/EditDeliveryCondition.vue';
import { DeliveryCondition } from '@/serializers/deliveryConditionsSerializer';
import { DeliveryTariff } from '@/serializers/deliveryTariffSerializer';
import EditableTitle from '@/components/EditableTitle.vue';

export default {
  name: 'EditDeliveryTariff',
  components: { EditableTitle, EditDeliveryCondition },
  props: {
    tariff: [DeliveryTariff],
  },
  emits: ['save'],
  data() {
    return {
      title: '',
      conditions: [],
    };
  },
  created() {
    this.setupDefaults();
  },
  methods: {
    setupDefaults() {
      this.title = this.tariff ? this.tariff.title : '';
      this.conditions = this.tariff ? this.tariff.conditions : [new DeliveryCondition()];
    },
    addNewCondition() {
      this.conditions.push(new DeliveryCondition());
    },
    deleteCondition(index) {
      this.conditions.splice(index, 1);
    },
    saveTariff() {
      const editedTariff = new DeliveryTariff();
      editedTariff.title = this.title;
      editedTariff.conditions = this.conditions;
      this.$emit('save', editedTariff);
    },
  },
  watch: {
    tariff() {
      this.setupDefaults();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/page.scss';

.title-view {
  max-width: 30%;
}
.condition {
  margin-right: 10px;
}
.condition-title {
  color: $default_dark_gray_color;
  font-family: $default_bold_font;
  font-size: 15px
}
</style>
