<template>
  <div
    class="br-8 px-3 py-2 mx-4 mb-2 d-flex flex-column"
    :style="`background-color: rgba(${deliveryAreaColors[zone.order - 1]}, 0.1)`"
  >
    <div class="d-flex align-center zone-details">
      <div class="zone-number text-no-wrap mr-1 subtitle-1 font-weight-bold">
        №{{ zone.order }}
      </div>
      <div v-if="zone.isEdited" class="d-flex">
        <VTextField
          class="br-8 mr-2 width-106"
          dense
          hide-details
          label="Радиус зоны"
          outlined
          placeholder="Введите значение"
          readonly
          :value="zone.radius.outer
                  || getRadiusBySetOfH3Indexes(Object.values(zone.areaKeys)).outer"
        />
        <DeliveryTariffSelection v-model="zone.deliveryTariff" />
        <DeliveryTimeSelection v-model="zone.deliveryTime" />
      </div>
      <div v-else>
        <span class="body-2">{{ zone.deliveryTariff.title }}</span><br>
        <span class="caption black--text">
          {{ `${zone.radius.inner} м - ${zone.radius.outer}  м` }}
        </span><br>
        <span class="caption">
          {{
            `${zone.deliveryTime.lowLimitMinutes} - ${zone.deliveryTime.upperLimitMinutes} минут`
          }}
        </span>
      </div>
      <VBtn
        v-show="!zone.isEdited"
        class="ml-auto"
        icon
        small
        @click="showEdit"
      >
        <VIcon v-text="'mdi-pencil-outline'" />
      </VBtn>
      <VBtn
        class="ml-2"
        icon
        small
        @click="deleteZone"
      >
        <VIcon v-text="'mdi-trash-can-outline'" />
      </VBtn>
    </div>
    <div v-show="zone.isEdited">
      <VDivider class="my-3"/>
      <div class="d-flex">
        <VTooltip
          bottom
          color="#232324CC"
          content-class="br-16 py-4 body-2"
          max-width="320"
        >
          <template v-slot:activator="{ on, attrs }">
            <VIcon
              v-bind="attrs"
              v-on="on"
              v-text="'mdi-information-outline'"
            />
          </template>
          Укажите мин. сумму заказа <br><br>
          Это калькутор расчета высчитывается по формуле:<br>
          мин.сумма заказа x процент комиссии - (ставка курьера + киллометраж
          курьера х радиус зоны от ресторана в км) = наша чистая прибыль
        </VTooltip>
        <VSelect
          v-model="zone.minSum"
          append-icon="mdi-chevron-down"
          class="ml-3 width-106"
          dense
          hide-details
          hide-selected
          :items="getZoneMinSums(zone.minSum)"
          label="Мин. сумма"
          outlined
          placeholder="Выберите из списка"
        />
        <div class="pl-2 w-full d-flex flex-column justify-center">
          <div
            class="d-flex justify-space-between mb-1"
            :class="zoneProfitability > 0 ? 'dark--green--text' : 'brick--text'"
          >
            <span class="font-10">
              <VTooltip
                bottom
                color="#232324CC"
                content-class="br-8"
                max-width="310"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="font-10"
                    v-on="on"
                  >
                    {{ zone.minSum }}
                  </span>
                </template>
                <span class="body-2">
                  мин. сумма заказа
                </span>
              </VTooltip>
              x
              <VTooltip
                bottom
                color="#232324CC"
                content-class="br-8"
                max-width="310"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="font-10"
                    v-on="on"
                  >
                    {{ commission }}%
                  </span>
                </template>
                <span class="body-2">
                  комиссия ресторана
                </span>
              </VTooltip>
              -
              <VTooltip
                bottom
                color="#232324CC"
                content-class="br-8"
                max-width="310"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="font-10"
                    v-on="on"
                  >
                    ({{ courierCostByCity[city.title].supply }}
                  </span>
                </template>
                <span class="body-2">
                  ставка курьера
                </span>
              </VTooltip>
              +
              <VTooltip
                bottom
                color="#232324CC"
                content-class="br-8"
                max-width="310"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="font-10"
                    v-on="on"
                  >
                    {{ courierCostByCity[city.title].km }}
                  </span>
                </template>
                <span class="body-2">
                  киллометраж
                </span>
              </VTooltip>
              x
              <VTooltip
                bottom
                color="#232324CC"
                content-class="br-8"
                max-width="310"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="font-10"
                    v-on="on"
                  >
                    {{ getMaxDistanceFromPoint(Object.values(zone.areaKeys),
                    restaurantCoordinates) }})
                  </span>
                </template>
                <span class="body-2">
                  радиус зоны от ресторана в км
                </span>
              </VTooltip>
              = {{ zoneProfitability }}
            </span>
            <span class="dashed-line" />
            <span class="font-10">
              {{ zoneProfitability }}₸
            </span>
          </div>
          <div class="d-flex justify-space-between">
            <span class="font-12">
              рекомендуемый тариф на доставку
            </span>
            <span class="dashed-line" />
            <span class="font-12">
              {{ recommendedDeliveryPrice }}₸
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeliveryTimeSelection from '@/components/DeliveryTimeSelection.vue';
import DeliveryTariffSelection from '@/components/DeliveryTariffSelection.vue';
import {
  deliveryAreaColors,
  courierCostByCity,
  zoneMinSums,
  getRadiusBySetOfH3Indexes, getMaxDistanceFromPoint,
} from '@/helpers/deliveryZone';

export default {
  name: 'DeliveryAreas',
  components: {
    DeliveryTariffSelection,
    DeliveryTimeSelection,
  },
  props: {
    city: {
      type: Object,
      required: true,
    },
    commission: {
      type: Number,
      default: 25,
    },
    zone: {
      type: Object,
      required: true,
    },
    order: {
      type: Number,
      default: 1,
    },
    restaurantCoordinates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      deliveryAreaColors,
      courierCostByCity,
      zoneMinSums,
      getRadiusBySetOfH3Indexes,
      getMaxDistanceFromPoint,
    };
  },
  computed: {
    zoneProfitability() {
      const profit = this.zone.minSum * (this.commission / 100)
        - (this.courierCostByCity[this.city.title].supply
        + this.courierCostByCity[this.city.title].km
        * getMaxDistanceFromPoint(Object.values(this.zone.areaKeys), this.restaurantCoordinates));
      return Math.round(profit * 10) / 10;
    },
    recommendedDeliveryPrice() {
      return Math.max(0, Math.ceil(300 - this.zoneProfitability));
    },
  },
  methods: {
    deleteZone() {
      this.$emit('delete');
    },
    getZoneMinSums(minSum) {
      this.zoneMinSums[minSum] = minSum;
      return Object.values(this.zoneMinSums);
    },
    showEdit() {
      this.zone.isEdited = true;
      this.$emit('isEdited');
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/scss/main.scss';

::v-deep {
  .zone-details .v-select {
    min-width: 120px;
  }
  .width-106 {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
  }
}

.zone-number {
  min-width: 32px;
  max-width: 32px;
}

.dashed-line {
  flex-grow: 1;
  border-bottom: 1px dashed #6D7885;
  margin: auto 4px;
}

.dark--green--text {
  color: #048848;
}
</style>
