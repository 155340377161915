<template>
  <div>
    <div v-if="deliveryTariffs.length">
      <VSimpleTable class="elevation-2">
        <thead>
          <tr class="titles-thead">
            <th class="text-left">
              Название
            </th>
            <th class="text-right">
              Условие
            </th>
            <th class="text-left">
              Минимальная сумма заказа
            </th>
            <th class="text-left">
              Стоимость доставки
            </th>
            <th class="text-left">
              Действия
            </th>
          </tr>
          <tr class="search-thead">
            <th>
              <VTextField
                v-model="filterByTitle"
                background-color="#5B5B5B"
                class="title-search"
                clearable
                dark
                dense
                hide-details
                outlined
                solo
              >
                <template v-slot:prepend-inner>
                  <VIcon color="white" v-text="'mdi-magnify'" />
                </template>
              </VTextField>
            </th>
            <th></th>
            <th>
              <VTextField
                v-model="filterByOrderMinCost"
                background-color="#5B5B5B"
                class="order-cost"
                clearable
                dark
                dense
                hide-details
                outlined
                solo
                type="number"
              >
                <template v-slot:prepend-inner>
                  <VIcon color="white" v-text="'mdi-magnify'" />
                </template>
              </VTextField>
            </th>
            <th>
              <VTextField
                v-model="filterByDeliveryCost"
                background-color="#5B5B5B"
                class="delivery-cost"
                clearable
                dark
                dense
                hide-details
                outlined
                solo
                type="number"
              >
                <template v-slot:prepend-inner>
                  <VIcon color="white" v-text="'mdi-magnify'" />
                </template>
              </VTextField>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="tariff in filteredDeliveryTariffs"
            :key="tariff.id"
            class="tariff"
          >
            <td>{{ tariff.title }}</td>
            <td>
              <div
                v-for="(_, index) in tariff.conditions"
                :key="index"
                class="pa-5 text-right"
              >
                #{{ index + 1 }}
              </div>
            </td>
            <td>
              <div
                v-for="(condition, index) in tariff.conditions"
                :key="index"
                class="pa-5"
              >
                {{ condition.orderMinCost }}
              </div>
            </td>
            <td>
              <div
                v-for="(condition, index) in tariff.conditions"
                :key="index"
                class="pa-5"
              >
                {{ condition.deliveryCost }}
              </div>
            </td>
            <td>
              <div class="d-flex align-center">
                <VBtn
                  class="tariff-edition"
                  icon
                  small
                  @click="editTariff(tariff)"
                >
                  <VIcon color="#D0021B">
                    mdi-pencil
                  </VIcon>
                </VBtn>
                <VBtn
                  class="tariff-deletion ml-3"
                  color="#D0021B"
                  fab
                  x-small
                  @click="deleteTariff(tariff)"
                >
                  <VIcon color="white">
                    mdi-delete
                  </VIcon>
                </VBtn>
              </div>
            </td>
          </tr>
        </tbody>
      </VSimpleTable>
    </div>
    <VAlert v-else type="info">
      Нет тарифов для отображения
    </VAlert>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { eventHub, events } from '@/global/eventhub';

export default {
  name: 'DeliveryTariffTable',
  emits: ['editTariff', 'deleteTariff'],
  data() {
    return {
      filterByTitle: null,
      filterByOrderMinCost: null,
      filterByDeliveryCost: null,
    };
  },
  computed: {
    ...mapGetters(['deliveryTariffs']),
    filteredDeliveryTariffs() {
      let tariffs = this.deliveryTariffs;
      const filterByTitle = this.filterByTitle && this.filterByTitle.toLowerCase();
      const filterByOrderMinCost = this.filterByOrderMinCost
        && parseInt(this.filterByOrderMinCost, 10);
      const filterByDeliveryCost = this.filterByDeliveryCost
        && parseInt(this.filterByDeliveryCost, 10);
      if (filterByTitle) {
        tariffs = tariffs.filter(
          (tariff) => tariff.title.toLowerCase().indexOf(filterByTitle) > -1,
        );
      }
      if (filterByOrderMinCost) {
        tariffs = tariffs.filter(
          (tariff) => tariff.conditions
            .reduce((ar, c) => ar.concat([c.orderMinCost]), [])
            .includes(filterByOrderMinCost),
        );
      }
      if (filterByDeliveryCost) {
        tariffs = tariffs.filter(
          (tariff) => tariff.conditions
            .reduce((ar, c) => ar.concat([c.deliveryCost]), [])
            .includes(filterByDeliveryCost),
        );
      }
      return tariffs;
    },
  },
  methods: {
    ...mapActions(['getDeliveryTariffs']),
    editTariff(tariff) {
      this.$emit('editTariff', tariff);
    },
    deleteTariff(tariff) {
      this.$emit('deleteTariff', tariff);
    },
  },
  created() {
    this.getDeliveryTariffs();
    eventHub.$on(events.deliveryTariffCreated, this.getDeliveryTariffs);
    eventHub.$on(events.deliveryTariffUpdated, this.getDeliveryTariffs);
    eventHub.$on(events.deliveryTariffDeleted, this.getDeliveryTariffs);
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/scss/mixin.scss';

.titles-thead th {
  color: $default_dark_gray_color !important;
  background-color: white;
}
.search-thead th {
  color: white !important;
  background-color: $default_gray_color;
}
tbody tr:nth-of-type(2n + 1) {
  background-color: rgba(0, 0, 0, 0.05);
}
a {
  text-decoration: none;
}
th, td {
  padding-left: 16px !important;
}
</style>
