<template>
  <div>
    <VAutocomplete
      v-model="selectedDeliveryTime"
      append-icon="mdi-chevron-down"
      dense
      hide-details
      hide-no-data
      hide-selected
      item-text="title"
      :items="deliveryTimes"
      label="Время доставки"
      outlined
      placeholder="Выберите из списка"
      return-object
      :rules="[(v) => !!v || 'Обязательное значение']"
      @change="$emit('input', $event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DeliveryTimeSelection',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['input'],
  data() {
    return {
      selectedDeliveryTime: null,
    };
  },
  mounted() {
    if (!this.deliveryTimes.length) {
      this.getDeliveryTimes();
    }
    this.selectedDeliveryTime = this.value;
  },
  computed: {
    ...mapGetters(['deliveryTimes']),
  },
  methods: {
    ...mapActions(['getDeliveryTimes']),
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(value) {
        this.selectedDeliveryTime = value;
      },
    },
  },
};
</script>
