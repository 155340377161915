<template>
  <div>
    <VDivider />
    <EditableTitle
      v-model="title"
      autofocus
      class="title-view my-4"
      placeholder="30% доставка, 500-3000 / 10% заказ, 3000-9999999"
    />
    <div class="d-flex flex-wrap justify-start">
      <CreateHotDeliveryCondition
        @conditions-change="conditions = $event"
      />
    </div>
    <div class="d-flex flex-column">
      <VBtn
        class="align-self-center default-btn white--text my-6"
        color="#4caf50"
        rounded
        @click="saveTariff"
      >
        Сохранить {{ title }} в список увеличенных тарифов
      </VBtn>
    </div>
    <VDivider class="mb-4" />
  </div>
</template>

<script>
import CreateHotDeliveryCondition from '@/components/CreateHotDeliveryCondition.vue';
import EditableTitle from '@/components/EditableTitle.vue';
import { eventHub } from '@/global/eventhub';

export default {
  name: 'CreateHotDeliveryTariff',
  components: { EditableTitle, CreateHotDeliveryCondition },
  emits: ['save'],
  data() {
    return {
      title: '',
      conditions: {},
    };
  },
  created() {
    this.setupDefaults();
  },
  methods: {
    saveTariff() {
      if (!this.title.trim().length) {
        eventHub.$emit('response-error', 'А название кто будет вводить? Пушкин?');
        this.$nextTick().then(() => {
          this.title = '';
          document.querySelector('.title-view')
            .scrollIntoView({ block: 'center', behavior: 'smooth' });
        });
        return;
      }
      const deserializedConditions = {
        title: this.title,
        delivery_increase_rate: this.conditions.deliveryIncreaseRate,
        min_delivery: this.conditions.minDelivery,
        max_delivery: this.conditions.maxDelivery,
        min_moa: this.conditions.minMoa,
        max_moa: this.conditions.maxMoa,
        moa_increase_rate: this.conditions.moaIncreaseRate,
        courier_fee: this.conditions.courierFee,
        is_active: true,
      };
      this.$emit('save', deserializedConditions);
    },
  },
  watch: {
    tariff() {
      this.setupDefaults();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/page.scss';

.title-view {
  max-width: 30%;
}
.condition {
  margin-right: 10px;
}
.condition-title {
  color: $default_dark_gray_color;
  font-family: $default_bold_font;
  font-size: 15px
}
</style>
