<template>
  <VDialog v-model="dialog" width="35vw">
    <VCard>
      <VCardTitle class="mb-4">
        <span class="page_title mr-4">{{ title }}</span>
      </VCardTitle>
      <div class="px-6">
        <VTextField
          v-model="cost"
          :rules="rules"
          single-line
          solo
          type="number"
        >
        </VTextField>
      </div>
      <div class="d-flex justify-center pa-3">
        <VBtn
          class="default-btn white--text"
          color="#4caf50"
          :disabled="btnDisabled"
          rounded
          @click="saveCost"
        >
          Добавить
        </VBtn>
      </div>
    </VCard>
  </VDialog>
</template>

<script>
import { ConditionCost } from '@/serializers/conditionCostSerializer';

export default {
  name: 'EditDeliveryModal',
  props: {
    title: {
      type: String,
      default: 'Создание',
    },
    type: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['save', 'appearance'],
  data() {
    return {
      rules: [
        value => parseInt(value, 10) >= 0 || 'Введите число!',
      ],
      cost: '',
      dialog: this.show,
    };
  },
  computed: {
    btnDisabled() {
      return Number.isNaN(parseInt(this.cost, 10));
    },
  },
  methods: {
    saveCost() {
      const conditionCost = new ConditionCost();
      conditionCost.cost = parseInt(this.cost, 10);
      conditionCost.type = this.type;
      this.$emit('save', conditionCost);
      this.dialog = false;
    },
  },
  watch: {
    show(bool) {
      this.dialog = bool;
    },
    dialog(dialog) {
      if (!dialog) {
        this.cost = '';
        this.$emit('appearance', dialog);
      }
    },
  },
};
</script>
