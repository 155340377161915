<template>
  <div>
    <span class="text-caption" :class="csv[fileState].class">
      {{ csv[fileState].label }}
    </span>
    <div class="d-flex">
      <VBtn
        v-if="fileState === 'load'"
        class="br-8"
        color="#E43539"
        :disabled="disabledBtn"
        height="38"
        outlined
        width="193"
      >
        <input
          id="actual-btn"
          ref="file"
          accept=".csv"
          hidden
          type="file"
          @change="selectFile"
        >
        <label class="black--text body-2" for="actual-btn">
          Выбрать файл .CSV
        </label>
      </VBtn>
      <VBtn
        v-else
        class="br-8"
        :color="csv[fileState].btnColor"
        :disabled="disabledBtn"
        height="38"
        min-width="193"
        outlined
        @click="csv[fileState].action()"
      >
        <span class="black--text body-2">{{ csv[fileState].btnText }}</span>
      </VBtn>
      <VCard
        v-if="file"
        class="ml-2 elevation-0"
        height="38"
        max-width="300"
      >
        <span>{{ file.name }}</span>
        <VIcon
          color="black"
          small
          @click="removeFile"
        >
          mdi-close
        </VIcon>
      </VCard>
    </div>
  </div>
</template>

<script>
import { uploadCsvFile } from '@/api/api';

export default {
  name: 'UploadCsvFile',
  data() {
    return {
      fileState: 'load',
      file: null,
      downloadFile: null,
      downloadFileName: null,
      disabledBtn: false,
    };
  },
  props: {
    areaLegendsLength: {
      type: Number,
      required: false,
      default: 0,
    },
    restId: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    csv() {
      return {
        load: {
          label: 'Загрузите файл с зонами доставки',
        },
        selected: {
          label: 'Загрузите файл с зонами доставки',
          btnText: 'Загрузить файл .CSV',
          btnColor: 'success',
          class: '',
          action: () => this.uploadFile(),
        },
        reload: {
          label: 'Ошибка загрузки файла, попробуйте повторить или поменять файл',
          btnText: 'Повторно загрузить файл .CSV',
          btnColor: '#E43539',
          class: 'red--text',
          action: () => this.uploadFile(),
        },
      };
    },
  },
  methods: {
    selectFile(event) {
      this.file = event.target.files[0];
      this.fileState = 'selected';
    },
    uploadFile() {
      if (!this.isCsvFile(this.file.name)) {
        this.fileState = 'reload';
        return;
      }
      const formData = new FormData();
      formData.append('file', this.file);
      this.disabledBtn = true;
      uploadCsvFile(formData, this.restId)
        .then((result) => {
          if (result.status === 200 || result.status === 201) {
            this.fileState = 'load';
            this.file = null;
            this.downloadFile = null;
            this.downloadFileName = null;
            this.disabledBtn = false;
          } else {
            this.fileState = 'reload';
          }
        })
        .catch(() => {
          this.fileState = 'reload';
        })
        .finally(() => {
          this.disabledBtn = false;
          this.$root.$emit('updateRestaurantAreas');
        });
    },
    removeFile() {
      this.file = null;
      this.fileState = 'load';
    },
    isCsvFile(filename) {
      const format = 'csv';
      const parts = filename.split('.');
      const ext = parts[parts.length - 1];
      return ext.toLowerCase() === format;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/main.scss';

.v-btn label, .v-icon {
  cursor: pointer;
}

.v-btn {
  margin-top: 2px;
}
</style>
