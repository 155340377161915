<template>
  <div class="pl-2 pt-2">
    <VChipGroup
      v-model="selectedTab"
      active-class="btn-color-bg"
      class="ml-6"
      mandatory
    >
      <VChip
        v-for="tab in tabs"
        :key="tab.title"
        class="default-button mr-2"
        large
        :value="tab.value"
      >
        {{ tab.title }}
      </VChip>
    </VChipGroup>
    <Component :is="selectedTab" :key="selectedTab" />
  </div>
</template>

<script>
import TarriffStandart from '@/components/TarriffStandart.vue';
import TarriffIncreased from '@/components/TarriffIncreased.vue';

export default {
  components: {
    TarriffStandart,
    TarriffIncreased,
  },
  data() {
    return {
      tabs: [
        {
          title: 'Стандартные тарифы',
          value: 'TarriffStandart',
        },
        {
          title: 'Увеличенные тарифы',
          value: 'TarriffIncreased',
        },
      ],
      selectedTab: 'TarriffStandart',
    };
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

</style>
