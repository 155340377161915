<template>
  <VContainer class="px-1 py-0 ma-0 areas-map-wrapper" fluid>
    <div id="leaflet-map" class="map" />
    <VTooltip
      v-model="restIconTooltip.show"
      absolute
      color="#000000CC"
      content-class="br-6 py-2"
      :position-x="restIconTooltip.x - 10"
      :position-y="restIconTooltip.y"
      right
    >
      <span>Добавить зону доставки</span>
    </VTooltip>
    <VMenu
      v-model="circleAreaMenu.enabled"
      :close-on-content-click="false"
      :position-x="circleAreaMenu.x + 30"
      :position-y="circleAreaMenu.y - 150"
    >
      <VCard class="br-8 pb-4" max-width="320">
        <div class="d-flex justify-space-between pl-4 pt-2 pr-2">
          <p class="my-2 body-2">Добавить условия доставки</p>
          <VBtn
            icon
            small
            @click="circleAreaMenu.enabled = false"
          >
            <VIcon size="16" v-text="'mdi-close'" />
          </VBtn>
        </div>
        <VForm
          v-model="valid"
          class="px-4"
        >
          <VDivider />
          <VTextField
            v-model="zoneQuantity"
            v-mask="'##'"
            class="my-3 br-8"
            dense
            hide-details
            label="Количество зон"
            outlined
            placeholder="от 1 до 20"
            :rules="rules.quantity"
            @input="$refs.zoneStep.validate()"
          />
          <VTextField
            ref="zoneStep"
            v-model="zoneStep"
            v-mask="'####'"
            class="br-8 mb-3"
            dense
            hide-details="auto"
            label="радиус зоны/шаг каждой зоны"
            outlined
            placeholder="от 100м до 4км"
            :rules="rules.radius"
            @blur="zoneStep = Math.round(zoneStep / 100) * 100"
          />
        </VForm>
        <div class="d-flex flex-wrap px-3">
          <VBtn
            v-for="(step) in zoneSteps"
            :key="step.title"
            class="mx-1 mb-2 elevation-0 br-4 px-2"
            color="#F4F6FF"
            height="32"
            @click="zoneStep = step.distance"
          >
            <span class="subtitle-2">{{ step.title }}</span>
          </VBtn>
        </div>
        <div class="px-4">
          <VBtn
            block
            class="elevation-0 br-8 mt-2"
            color="#E43539"
            :disabled="!valid"
            height="38"
            @click="createZoneConditions"
          >
            <span class="white--text body-1">Продолжить</span>
          </VBtn>
        </div>
      </VCard>
    </VMenu>
  </VContainer>
</template>

<script>
import L from 'leaflet';
import 'leaflet-lasso';
import {
  geoToH3, h3ToGeoBoundary, polyfill,
} from 'h3-js';
import settings from '@/global/global-settings';
import { Coordinate } from '@/serializers/coordinateSerializer';
import iconRestaurant from '@/assets/icons/icon-restaurant.svg';
import iconMarker from '@/assets/icon-marker.png';
import { deliveryAreaColors, zoneSteps } from '@/helpers/deliveryZone';

export default {
  name: 'AreasMap',
  props: {
    restaurantLocation: [Coordinate],
    savedZones: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['changedSelectedAreas', 'deleteHexFromSavedZone'],
  data() {
    return {
      map: null,
      restIcon: null,
      lassoControl: null,
      savedDrawnHexagons: {}, // { hex: polygon }
      newDrawnHexagons: {}, // { hex: polygon }
      getSavedZoneOrderByDrawnHex: {}, // { hex: savedZoneOrder }
      getNewZoneIndexByDrawnHex: {}, // { hex: newZoneIndex }
      circleAreaMenu: {
        enabled: false,
        x: 0,
        y: 0,
      },
      selectPollygon: false,
      markerGroups: {},
      pollygonAreas: [],
      hasClickedOnMap: false,
      zoneSteps,
      zoneQuantity: null,
      zoneStep: null,
      newZones: {},
      rules: {
        quantity: [(v) => (v > 0 && v <= 20) || 'Введите значение от 1 до 20'],
        radius: [
          (v) => (v && v > 99) || 'Введите значение от 100 до 4000',
          (v) => (this.zoneQuantity * v <= 4000) || 'Общий радиус зон превысил 4000км',
        ],
      },
      valid: true,
      zonesCount: {},
      restIconTooltip: {
        show: false,
        x: 0,
        y: 0,
      },
    };
  },
  mounted() {
    this.initMap();
  },
  computed: {
    zonesToEmit() {
      return Object.values(this.newZones);
    },
    lastZoneOrder() {
      const zones = this.zonesToEmit.length ? this.zonesToEmit : this.savedZones;
      return zones[zones.length - 1]?.order || 0;
    },
  },
  methods: {
    initMap() {
      this.map = L.map('leaflet-map', { zoomControl: false });
      this.markerGroups = L.featureGroup().addTo(this.map);
      L.tileLayer('https://tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1&ts=online_sd').addTo(this.map);

      this.pollygonalSelection();

      this.lassoControl = L.control.lasso().addTo(this.map);
      this.map.on('click', (event) => this.clickOnMap(event.latlng));
      this.map.on('contextmenu', (event) => this.rightClickOnMap(event.latlng));
      // this.map.on('keypress', (event) => {
      //   if (event.originalEvent.code === 'KeyS') {
      //     this.lassoControl.toggle();
      //   } else if (event.originalEvent.code === 'KeyQ') {
      //     this.polygonActions(document.querySelector('.penta'));
      //   }
      // });
      this.map.on('lasso.finished', (event) => this.lassoSelectLatLongs(event.latLngs));
      this.setupRestaurantLocation();
      L.control.zoom({ position: 'topright' }).addTo(this.map);
    },
    setupRestaurantLocation() {
      const mapCenter = this.restaurantLocation
        ? this.restaurantLocation.coordinates
        : [47.926363, 71.336273];
      const zoom = this.restaurantLocation ? 14 : 5.4;
      this.map.setView(mapCenter, zoom);

      if (this.restIcon) { this.restIcon.remove(); }
      if (this.restaurantLocation) {
        const icon = L.icon({ iconUrl: iconRestaurant, iconSize: [55, 55] });
        this.restIcon = L.marker(mapCenter, { icon }).addTo(this.map);
        this.restIcon.on('click', this.clickOnRestIcon);
        this.restIcon.on('mouseover', (e) => {
          this.restIconTooltip.x = e.originalEvent.clientX;
          this.restIconTooltip.y = e.originalEvent.clientY;
          this.restIconTooltip.show = true;
        });
        this.restIcon.on('mouseout', () => {
          this.restIconTooltip.show = false;
        });
      }
    },
    pollygonalSelection() {
      L.Control.Watermark = L.Control.extend({
        onAdd: () => {
          const span = L.DomUtil.create('span', '');
          span.classList = ['mdi mdi-hexagon-outline penta'];
          const styles = {
            width: '46px',
            height: '46px',
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '23px 0 0 23px',
            transform: 'rotate(90deg)',
          };
          Object.assign(span.style, styles);
          span.setAttribute('title', 'Выбор гексагонов');
          span.addEventListener('click', (e) => {
            e.stopPropagation();
            this.polygonActions(span);
          });
          return span;
        },
      });
      L.control.watermark = (opts) => new L.Control.Watermark(opts);
      L.control.watermark({ position: 'topright' }).addTo(this.map);
    },
    polygonActions(span) {
      this.selectPollygon = !this.selectPollygon;
      if (!this.selectPollygon) {
        const pollygons = this.pollygonAreas.map((area) => [area.lat, area.lng]);
        this.markerGroups.clearLayers();
        const h3Pollygons = polyfill(pollygons, settings.h3_hexagon_resolution);
        const zoneKey = this.lastZoneOrder;
        this.createNewZone(zoneKey);
        h3Pollygons.forEach((hex) => this.selectArea(hex, zoneKey));
        this.pollygonAreas = [];
      }
      span.classList.add(this.selectPollygon ? 'red--text' : 'black--text');
      span.classList.remove(this.selectPollygon ? 'black--text' : 'red--text');
    },
    drawSavedZones(zones) {
      zones.forEach((zone) => {
        zone.areaKeys.forEach((hex) => {
          this.drawSavedHexagon(hex, zone.order - 1);
          this.getSavedZoneOrderByDrawnHex[hex] = zone.order;
        });
      });
    },
    createZoneConditions() {
      let radius = Number(this.zoneStep);
      const newZones = Object.keys(this.newZones);
      for (let i = 0; i < this.zoneQuantity; i += 1) {
        this.selectCircleRestaurantArea(radius, newZones.length + i);
        radius += Number(this.zoneStep);
      }
      this.zoneQuantity = null;
      this.zoneStep = null;
      this.circleAreaMenu.enabled = false;
    },
    createNewZone(key = 0, radius = null) {
      this.$set(this.newZones, key, {
        order: this.lastZoneOrder + 1,
        key,
        radius: {
          inner: null,
          outer: radius,
        },
        areaKeys: {},
        minSum: 2000,
        deliveryTariff: null,
        deliveryTime: null,
        isEdited: true,
        overlapping: {}, // { hex: savedZoneOrder }
      });
    },
    selectCircleRestaurantArea(radius, zoneKey) {
      /* eslint no-underscore-dangle: 0 */
      this.createNewZone(zoneKey, radius);
      const restLatLng = this.restIcon._latlng;
      const restPoint = this.map.latLngToLayerPoint(restLatLng);
      const pixelRadius = radius / this.metersPerPixel(restLatLng.lat, this.map._zoom);
      const points = [];
      const countPoints = 33;
      const doublePi = Math.PI * 2;

      let angle = 0.0;
      for (let i = 0; i < countPoints - 1; i += 1) {
        angle -= (doublePi / countPoints);
        const point = new L.Point(
          restPoint.x + (pixelRadius * Math.cos(angle)),
          restPoint.y + (pixelRadius * Math.sin(angle)),
        );
        points.push(this.map.layerPointToLatLng(point));
      }
      const mappedLatLng = points.map((ll) => [ll.lat, ll.lng]);
      const hexagons = polyfill(mappedLatLng, settings.h3_hexagon_resolution);
      hexagons.forEach((hex) => this.selectArea(hex, zoneKey, true));
    },
    selectArea(hex, zoneKey, noOverlap = false) {
      const isNewDrawn = hex in this.newDrawnHexagons;
      if (isNewDrawn && noOverlap) { return; }
      if (isNewDrawn) {
        this.removeNewDrawnHex(hex);
        this.$delete(this.newZones[zoneKey].overlapping, hex);
      }
      const isSaved = hex in this.savedDrawnHexagons;
      if (isSaved) {
        this.newZones[zoneKey].overlapping[hex] = this.getSavedZoneOrderByDrawnHex[hex];
      }
      this.drawNewHexagon(hex, this.newZones[zoneKey].order - 1);
      this.$set(this.newZones[zoneKey].areaKeys, hex, hex);
      this.getNewZoneIndexByDrawnHex[hex] = zoneKey;
    },
    drawSavedHexagon(hexagon, colorIndex) {
      const polygon = h3ToGeoBoundary(hexagon);
      this.savedDrawnHexagons[hexagon] = L
        .polygon(polygon, {
          color: `rgba(${deliveryAreaColors[colorIndex]})`,
          fillOpacity: 0.5,
        })
        .addTo(this.map);
    },
    drawNewHexagon(hexagon, colorIndex) {
      const polygon = h3ToGeoBoundary(hexagon);
      this.newDrawnHexagons[hexagon] = L
        .polygon(polygon, {
          color: `rgba(${deliveryAreaColors[colorIndex]})`,
          fillOpacity: 0.5,
        })
        .addTo(this.map);
    },
    clearMap() {
      Object.values(this.savedDrawnHexagons).forEach((hexPolygon) => {
        if (hexPolygon) {
          hexPolygon.remove();
        }
      });
      Object.values(this.newDrawnHexagons).forEach((hexPolygon) => {
        if (hexPolygon) {
          hexPolygon.remove();
        }
      });
      this.savedDrawnHexagons = {};
      this.newDrawnHexagons = {};
      this.newZones = {};
      this.getSavedZoneOrderByDrawnHex = {};
      this.getNewZoneIndexByDrawnHex = {};
    },
    clickOnMap(latLong) {
      if (this.selectPollygon) {
        this.pollygonAreas.push(latLong);
        const icon = L.icon({
          iconUrl: iconMarker,
          iconSize: [30, 40],
          iconAnchor: [15, 30],
          className: 'icon',
        });
        L.marker([latLong.lat, latLong.lng], { icon }).addTo(this.markerGroups);
      } else {
        const hex = geoToH3(latLong.lat, latLong.lng, settings.h3_hexagon_resolution);
        if (!this.hasClickedOnMap || !Object.keys(this.newZones).length) {
          this.hasClickedOnMap = true;
          this.createNewZone(Object.keys(this.newZones).length);
        }
        const zoneKey = Object.keys(this.newZones).length - 1;
        this.selectArea(hex, zoneKey);
      }
    },
    rightClickOnMap(latLong) {
      const hex = geoToH3(latLong.lat, latLong.lng, settings.h3_hexagon_resolution);
      if (hex in this.savedDrawnHexagons) {
        this.removeSavedDrawnHex(hex);
        this.$emit('deleteHexFromSavedZone', {
          hex,
          zoneOrder: this.getSavedZoneOrderByDrawnHex[hex],
        });
      }
      if (hex in this.newDrawnHexagons) {
        this.removeNewDrawnHex(hex);
      }
    },
    removeSavedDrawnHex(hex) {
      const hexPolygon = this.savedDrawnHexagons[hex];
      if (hexPolygon) {
        hexPolygon.remove();
        this.$delete(this.savedDrawnHexagons, hex);
      }
    },
    removeNewDrawnHex(hex) {
      const hexPolygon = this.newDrawnHexagons[hex];
      if (hexPolygon) {
        hexPolygon.remove();
        delete this.newDrawnHexagons[hex];
      }
      const key = this.getNewZoneIndexByDrawnHex[hex];
      this.$delete(this.newZones[key].areaKeys, hex);
      if (Object.keys(this.newZones[key].areaKeys).length === 0) {
        this.$delete(this.newZones, key);
      }
    },
    clickOnRestIcon(e) {
      this.circleAreaMenu.x = e.originalEvent.clientX;
      this.circleAreaMenu.y = e.originalEvent.clientY;
      this.$nextTick().then(() => {
        this.circleAreaMenu.enabled = true;
      });
    },
    lassoSelectLatLongs(latLongs) {
      const zoneKey = Object.keys(this.newZones).length;
      this.createNewZone(zoneKey);
      const mappedLatLng = latLongs.map((ll) => [ll.lat, ll.lng]);
      polyfill(mappedLatLng, settings.h3_hexagon_resolution)
        .forEach((hex) => this.selectArea(hex, zoneKey));
    },
    metersPerPixel(latitude, zoomLevel) {
      const earthCircumference = 40075017;
      const latitudeRadians = latitude * (Math.PI / 180);
      return (earthCircumference * Math.cos(latitudeRadians)) / (2 ** (zoomLevel + 8));
    },
    deleteNewZone(key) {
      Object.keys(this.newZones[key].areaKeys).forEach(this.removeNewDrawnHex);
    },
  },
  watch: {
    restaurantLocation() {
      this.setupRestaurantLocation();
    },
    zonesToEmit(val) {
      this.$emit('changedSelectedAreas', val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~leaflet/dist/leaflet.css";
@import 'src/assets/scss/page.scss';
@import 'src/assets/scss/mixin.scss';
@import 'src/assets/scss/main.scss';

.areas-map-wrapper{
  position: relative;
  .map {
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0.5px solid #E6E0EC;
    border-radius: 16px;
  }
  .leaflet-marker-icon {
    box-shadow: 1px 1px 1px 10px black;
  }
}
::v-deep {
  .leaflet-top {
    top: 50% !important;
  }
  .leaflet-control {
    background-color: white;
    cursor: pointer;
    border-radius: 23px;
    box-shadow: 0 4px 8px 2px #0000001F;
    &:hover {
      background-color: #f5f5f5;
    }
  }
  .leaflet-bar {
    border: none;
    margin-top: 0;
    margin-bottom: 10px;
    a {
      width: 46px;
      height: 46px;
    }
    a:last-child {
      border-radius: 0 0 23px 23px;
      padding-top: 2px;
    }
  }
  .leaflet-control-zoom {
    box-shadow: 0 4px 8px 2px #0000001F;
    border-radius: 23px;
    overflow: hidden;
    a {
      font-size: 19px;
      font-weight: lighter;
      border-bottom: none;

      &:first-child {
        padding-top: 6px;
      }
    }
  }
  .leaflet-control-lasso {
    background-image: url('../assets/icons/lasso.svg');
  }
  .leaflet-lasso-active .leaflet-control-lasso {
    background-image: url('../assets/icons/lasso-red.svg');
  }
}
</style>
